import * as React from 'react'
import Layout from '../layout/layout'

const IndexPage = () => {

  return (
    <Layout pageTitle="Not Found - JVR Research" pageHeading="Page Not Found.">

    </Layout>
  )
  
}

export default IndexPage